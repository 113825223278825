import React, { useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import Layout from '@/components/layout';
import { Helmet } from 'react-helmet';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import OfferBanner from '@/components/lab-components/OfferBanner';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';

const tipsData = [
  {
    icon: "icon2-chat-blue",
    heading: "When in doubt, reach out",
    description: "<p>Scammers may contact you pretending to be from Latitude. If unsure, contact Latitude directly using the phone number on the back of your credit card or a trusted online search. Never use details they provide.</p>",
  },
  {
    icon: "icon2-attention-blue",
    heading: "Check who's ringing or buzzing ",
    description: "<p>Scope out the sender's email address for any irregularities, and keep a wary eye on those mysterious phone numbers.</p>",
  },
  {
    icon: "icon2-envelope-blue",
    heading: "Watch out for sneaky links and attachments",
    description: "<p>Instead of clicking links, navigate to our website through your trusty browser or hop on our app. And remember, unsolicited attachments are best left unopened.</p>",
  },
  {
    icon: "icon2-megaphone-blue",
    heading: "Report scams to us",
    description: "<p>If you stumble upon a Latitude branded scam, report it to <a>scams@latitudefinancial.com</a> and delete the message.</p>",
  },
  {
    icon: "icon2-bills-blue",
    heading: "Keep an eye on your accounts",
    description: "<p>If you catch any unusual or suspicious activity, contact your bank or credit provider ASAP.</p>",
  },
  {
    icon: "icon2-lock-blue",
    heading: "Create strong and unique passphrases",
    description: "<p>Choose a series of words combined with upper and lowercase letters, along with special characters. Avoid easily guessable information, and when possible, activate multi-factor authentication for added security.</p>",
  }
]

const SaferInternetDayPage = (props) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/safer-internet-day/"
          />
          <title>Safer Internet Day | Latitude Financial</title>
          <meta name="description" content="Latitude Safer Internet Day" />
        </Helmet>

        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        {state?.offerBannerData?.[0] && 
          <OfferBanner
            {...state?.offerBannerData?.[0]}
          />
        }

        <TopTipSection />
      </main>
    </Layout>
  );
};

const TopTipSection = () => (
  <Section id="top-tips"  
    css={`
      background-color: #E5F6FF;
      padding-bottom: 60px;
    `}
  >
    <h3 style={{ color: "#000" }}>Top tips to keep our online community safe and secure</h3>

    <div css={`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 32px;
    `}>
      {tipsData.map((tip, index) => (
        <div
          key={'tip'+ index}
          css={`
            background: #FFF;
            padding: 32px;
            
            p {
              font-size: 18px;
              }
              
              @media (min-width: 768px) {
                min-height: 460px;
                flex: 1 1 30%;
            }
          `}
        >
          <SvgInline name={tip.icon} />
          <h4 style={{ color: "#000" }}>{tip.heading}</h4>
          <div>
            {ReactHTMLParser(tip.description)}
          </div>
        </div>
      )) }
    </div>

  </Section>
)

export default SaferInternetDayPage;
